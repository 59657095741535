<template>
  <b-container fluid>
    <b-breadcrumb :items="bread"></b-breadcrumb>
    <b-tabs content-class="mt-3" v-if="farmer">
      <b-tab title="Farmer details" :active="active">
        <b-row>
          <b-col cols="auto">
            <b-form-group label="Outgrower name in system">
              <b-form-input disabled :value="farmer.title"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group label="Farmer Name">
              <b-form-input disabled :value="farmer.title"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group label="Product">
              <b-form-input disabled :value="farmer.subcategory"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group label="Packhouse Name">
              <b-form-input disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group label="Varieties">
              <b-form-input disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group label="PUC">
              <b-form-input disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group label="PHC">
              <b-form-input disabled></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="active && farmer.latitude && farmer.longitude">
          <b-col style="height: 300px">
            <l-map
              :options="mapOptions"
              :center="[farmer.latitude, farmer.longitude]"
            >
              <l-tile-layer :url="url"></l-tile-layer>
              <l-marker
                :lat-lng="[farmer.latitude, farmer.longitude]"
                :icon="markerIcon"
              ></l-marker>
            </l-map>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Contact details" @click="getWiki(farmer.uniqueId)">
        <div v-if="wiki" v-html="wiki.html"></div>
      </b-tab>
      <b-tab title="Compliance" disabled></b-tab>
      <b-tab title="Global Gap" disabled></b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import L from "leaflet";

export default {
  mounted() {
    this.active = true;
  },
  computed: {
    farmer() {
      return this.$route ? this.$route.params : null;
    }
  },
  data: () => ({
    prevRoute: null,
    bread: [],
    wiki: null,
    active: null,
    url:
      "https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=r5DE8X2qpUTOW5p9BdP2DbeQRXMjzGlxs870VpzJCl5wMsy5tMt7bPKdBx7yIc8S",
    mapOptions: {
      minZoom: 3,
      zoom: 10,
      maxZoom: 17,
      attributionControl: false
    },
    markerIcon: L.BeautifyIcon.icon({
      icon: "tractor",
      iconShape: "marker",
      borderColor: "red",
      backgroundColor: "red",
      textColor: "white"
    })
  }),
  methods: {
    async getWiki(profile) {
      await this.axios
        .get("wiki", {
          params: {
            profile
          }
        })
        .then(({ data }) => {
          const wiki = data[0].objectList[0];
          wiki.html = wiki.html.replace("pagedList", "table table-sm border");
          this.wiki = wiki;
        });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.bread = [
        {
          text: from.name,
          to: { name: from.name }
        },
        { text: "Farmer details", active: true }
      ];
    });
  }
};
</script>
<!-- 
<style lang="scss">
.wiki-container {
  .pagedList{
    // width: 100%;
  }
}
</style> -->
